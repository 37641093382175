// @mui material components
import Card from "@mui/material/Card";
import { Alert } from "@mui/material";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Linking } from "react";

// Authentication layout components

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

import { useAuth } from "../../contexts/authContext";

import axios from "axios";

import Api from "constants/Api";

function Referral() {
  let { isAuthenticated, roles, merchantId, token } = useAuth();

  const handleDownload = async () => {
    //change api endpoin
    const url = `${Api.report}/ReferralProgram/getTransactionsWithDiffTimeAsCsv`;

    console.log(url);
    // Construct the transaction filter based on your requirements
    const transactionFilter = {
      // Add any other required fields
    };

    try {
      const response = await axios.post(url, transactionFilter, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the Authorization header with your token
          "Content-Type": "application/json", // Ensure content type is correct
        },
        responseType: "blob", // Important for handling binary data
      });

      // Create a Blob from the response data (which is your CSV file)
      const blob = new Blob([response.data], { type: "text/csv" });

      // Create a URL for the blob
      const downloadUrl = window.URL.createObjectURL(blob);

      // Create a temporary anchor element to initiate the download
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "ReferralProgramReport.csv"); // Set the file name

      // Append the anchor to the body (required for some browsers)
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the anchor from the document
      document.body.removeChild(link);

      console.log("Download successful!");
    } catch (err) {
      console.error("There was an error in downloading the file!", err);
      if (err.response && (err.response.status === 401 || err.response.status === 403)) {
        console.error("Unauthorized or Forbidden error", err);
        navigate("/authentication/sign-in");
      }
    }
  };

  return (
    <DashboardLayout>
      <MDBox mt={8}>
        <Card>
          <MDBox ml={4} mt={2} mb={1}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                handleDownload();
                //setList(false);
              }}
              size="small"
            >
              Download
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Referral;
