import { textAlign } from "@mui/system";
import PageLayout from "examples/LayoutContainers/PageLayout";
import React from "react";
import { useParams } from "react-router-dom";
import logoImage from "./../../assets/images/logos/pplLogoOnly.png"; // Make sure to provide the correct path to the image

const WelcomeToApp = () => {
  const params = useParams();
  const name = params.name;
  const code = params.code;

  // Define your app's deep link URL
  const appDeepLink = "privilegeplus://Home"; // Replace with your actual deep link

  const learnMoreDeepLink = "privilegeplus://RaffleExpand"; // Deep link to open the "Raffle" screen

  const handleOpenApp = () => {
    window.location.href = appDeepLink;
  };

  const handleLearnMore = () => {
    window.location.href = learnMoreDeepLink;
  };
  return (
    <PageLayout>
      <center>
        <div
          style={{
            backgroundColor: "#fff",
            //width: "70%",
            margin: "0 auto",
            fontFamily: "Roboto, sans-serif",
            textAlign: "center",
            margin: 0,
            paddingTop: "15%",
            paddingBottom: "10%",

            color: "#ab8e66",
            borderLeft: "5px solid #ab8e66",
            borderRight: "5px solid #ab8e66",
            borderBottom: "5px solid #ab8e66",
            borderTop: "5px solid #ab8e66",
            backgroundImage:
              "url('https://dev-dashboard.privilegeplusloyaltyrewards.com/Asset2.png')",
          }}
        >
          <img
            src={logoImage}
            width="45%"
            height="40%"
            style={{
              margin: "0",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            alt="Logo"
          />

          <div
            style={{
              margin: "0 auto",
              //width: "70%",
              marginTop: "4%",
              marginBottom: "2%",
              //paddingRight: "8px",
              //paddingLeft: "8px",
              display: "flex",
              marginLeft: "auto",
              marginRight: "auto",
              fontSize: "16px",
              color: "#ab8e66",
            }}
          >
            <div style={{ width: "25%" }}>
              <u>Shop</u>
            </div>
            <div style={{ width: "25%" }}>
              <u>Dine</u>
            </div>
            <div style={{ width: "25%" }}>
              <u>Beauty & Services</u>
            </div>
            <div style={{ width: "25%", textAlign: "center" }}>
              <u>Unwind</u>
            </div>
          </div>
          <div style={{ height: "20px" }}></div>

          <div
            style={{
              //width: "80%",
              margin: "0 auto",
              paddingRight: "8px",
              paddingLeft: "8px",
              textAlign: "center",
              color: "#000",
              fontFamily: "arial",
              fontSize: "14px",
              display: "block",
            }}
          >
            <p
              style={{
                color: "#ab8e66",
                fontSize: "20px",
                fontFamily: "Georgia, serif",
              }}
            >
              WELCOME, {name.toUpperCase()}
            </p>
            <p>Thank you for signing up!</p>
            <br />
            <br />
            <div
              style={{
                //width: "60%",
                display: "block",
                padding: "8px",
                paddingTop: "6%",
                paddingBottom: "6%",
                marginLeft: "12%",
                marginRight: "12%",
                borderLeft: "1.5px solid #ab8e66",
                borderRight: "1.5px solid #ab8e66",
                borderBottom: "1.5px solid #ab8e66",
                borderTop: "1.5px solid #ab8e66",
                borderWidth: "1.5px",
                borderColor: "#ab8e66",
                //fontFamily: "arial",
                fontSize: "14px",
                color: "#ab8e66",
                maxWidth: "260px",
                margin: "0 auto",
                width: "90%",
                //backgroundColor: "ab8e66",
              }}
            >
              <p>Your PrivilegePLUS ID number is:</p>
              <p>
                <b>{code}</b>
              </p>
            </div>
            <br />

            <div
              style={{
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              <button
                onClick={handleOpenApp}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  color: "#fff",
                  backgroundColor: "#ab8e66",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Open App
              </button>

              <button
                onClick={handleLearnMore}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  color: "#fff",
                  backgroundColor: "#6e8b3d",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Learn More
              </button>
            </div>
            <div
              style={{
                margin: "auto",
                width: "100%",
                textAlign: "center",
                marginTop: "6%",
              }}
            >
              <p style={{ padding: "0px 2px" }}>
                We are delighted to have you on board.
                <br />
                With our PrivilegePLUS loyalty program, you get access to instant discounts, earn
                points on every purchase, and experience everything PrivilegePLUS has to offer at
                Mercato and Town Centre Jumeirah.
              </p>
            </div>
            <div style={{ height: "15px" }}></div>
          </div>
          <div style={{ height: "10px" }}></div>
          {/*<div
            style={{
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            <button
              onClick={handleOpenApp}
              style={{
                padding: "10px 20px",
                fontSize: "16px",
                color: "#fff",
                backgroundColor: "#ab8e66",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Open App
            </button>
          </div>*/}
        </div>
      </center>
    </PageLayout>
  );
};

export default WelcomeToApp;
